import React from 'react';
import t from '@jetshop/intl';
import { Link } from 'react-router-dom';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import UserIcon from '../../../ui/icons/UserIcon';
import { styled } from 'linaria/react';

const Wrapper = styled('div')``;

export const LoginWidgetMobile = ({ className }) => {
  const { loggedIn, logOut } = useAuth();
  const { routes } = useShopConfig();

  return (
    <Wrapper className={className}>
      <div className="icon-wrapper">
        <UserIcon />
      </div>
      {loggedIn && <Link to={routes.myPages.path}>{t('My Pages')}</Link>}
      {!loggedIn ? (
        <Link to={routes.login.path}>
          {t('Login')}/{t('My Pages')}
        </Link>
      ) : (
        <button onClick={logOut}>{t('Log Out')}</button>
      )}
    </Wrapper>
  );
};
