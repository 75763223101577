import React from 'react';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import { SearchField } from '../Search';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer';
import CartButton from '../../../Cart/CartButton';
import { Inner, PagePadding } from '../../MaxWidth';
import TopNav from '../Util/TopNav';
import Logo from '../Logo';
import SearchIcon from '../../../ui/icons/SearchIcon';
import MenuIcon from '../../../ui/icons/MenuIcon';
import { theme } from '../../../Theming/Theming';
import { FavouriteCount } from '../../../ProductList/FavouriteCount';

const MobileHeaderWrapper = styled('div')`
  background: ${theme.mobileHeader.background};
  height: ${theme.mobileHeader.height.xs + 'px'};
  box-shadow: 0px 1px 3px #0000000d;
  ${theme.only.sm} {
    height: ${theme.mobileHeader.height.sm + 'px'};
  }
  ul.top-nav-list {
    height: 100%;
    align-items: center;
    > li {
      padding: 0 0.4rem;
    }
    > li > a,
    > li > button {
    }
  }
  .page-padding,
  .inner {
    height: 100%;
  }
  .inner {
    display: flex;
    align-items: center;
  }
  .header-icon {
    font-size: 20px;
    color: ${theme.mobileHeader.color};
  }
  .header-button {
    position: relative;
    display: flex;
    align-items: center;
  }
`;

const StoreLogo = styled('div')`
  text-align: center;
  text-decoration: none;
  color: inherit;
  width: 120px;
  margin: 0 auto 0;
  a {
    text-decoration: none;
    > img,
    > svg {
      max-width: 100%;
    }
  }
`;

const MenuBar = styled('div')`
  position: relative;
  height: ${theme.mobileHeader.height.xs + 'px'};
  ${theme.only.sm} {
    height: ${theme.mobileHeader.height.sm + 'px'};
  }
`;

const MenuButton = styled('button')``;

const SearchButtonToggle = styled('button')``;

const SearchFieldWrapper = styled('div')`
  width: 100%;
  position: fixed;
  left: 0;
  top: ${theme.topBar.height.md + 'px'};
  z-index: 1;
  background: #f9f9f9;
  height: ${theme.mobileHeader.height.xs + 'px'};
  &[data-scrolling='true'] {
    top: 0;
  }
  ${theme.only.sm} {
    height: ${theme.mobileHeader.height.sm + 'px'};
  }
  .search-wrapper {
    height: 100%;
  }
  input {
    height: 100%;
    border-bottom: none;
    background: transparent;
  }
`;

const MobileHeader = ({ setSearch, searchOpen, scrolling }) => {
  return (
    <MobileHeaderWrapper>
      <MenuBar>
        <PagePadding>
          <Inner className="inner">
            <TopNav left>
              <DrawerTrigger id="menuDrawer" coverStyles={{ zIndex: 104 }}>
                {({ showTarget, hideTarget, isOpen }) => (
                  <MenuButton
                    onClick={isOpen ? hideTarget : showTarget}
                    className="header-button"
                  >
                    <MenuIcon className="header-icon" />
                  </MenuButton>
                )}
              </DrawerTrigger>
              {searchOpen ? (
                <SearchFieldWrapper data-scrolling={scrolling}>
                  <SearchField
                    onCancel={() => setSearch(false)}
                    setSearch={setSearch}
                    searchOpen={searchOpen}
                    scrolling={scrolling}
                    focusOnLoad={true}
                  />
                </SearchFieldWrapper>
              ) : (
                <SearchButtonToggle
                  href="#"
                  onClick={() => setSearch(true)}
                  className="header-button"
                >
                  <SearchIcon className="header-icon" />
                </SearchButtonToggle>
              )}
            </TopNav>
            <StoreLogo>
              <Link to="/">
                <Logo mobile={true} />
              </Link>
            </StoreLogo>
            <TopNav right>
              <FavouriteCount className="header-button" />
              <CartButton className="header-button" />
            </TopNav>
          </Inner>
        </PagePadding>
      </MenuBar>
    </MobileHeaderWrapper>
  );
};

export default MobileHeader;
