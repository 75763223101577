import { useContext } from 'react';
import useScript from '../ui/hooks/useScript';
import ChannelContext from '@jetshop/core/components/ChannelContext/ChannelContext';

export function LipscoreScript() {
  if (typeof window !== 'undefined') {
    window.lipscoreInit = function () {
      window.lipscore.init({
        apiKey: 'f3b97179f1ec85be1c2a7d27',
      });
    };
  }
  const { selectedChannel } = useContext(ChannelContext);
  const lang = selectedChannel.language.culture.substring(0, 2);

  useScript(`//static.lipscore.com/assets/${lang}/lipscore-v1.js`);

  return null;
}
