import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import MaxWidth from '../MaxWidth';
import Image from '@jetshop/ui/Image';
import NewsletterBox from './Newsletter/NewsletterBox';
import { theme } from '../../Theming/Theming';
import SiteContentContext from '../../SiteContent/SiteContentContext';

const FooterWrapper = styled('footer')`
  background: ${theme.footer.background};
  z-index: 2;
  a,
  a:active {
    text-decoration: none;
    color: ${theme.footer.color};
  }
  .footer-upper-wrapper {
    padding: 50px 0 0;
    a {
    }
  }
  .footer-content {
    padding: 3rem 0 0;
    color: ${theme.footer.color};
  }
`;

export const FooterContentWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0 1rem;
  padding-top: 0.5rem;
  margin-bottom: 3rem;
  ${theme.below.sm} {
    flex-direction: column;
  }
  > div {
    width: 100%;
    padding: 2rem 0;
    ${theme.only.md} {
      width: 40%;
      text-align: center;
    }
    ${theme.above.lg} {
      width: 17.5%;
      padding: 3rem 2rem;
      &.wide {
        width: 30%;
      }
    }
    .image {
      margin-bottom: 2rem;
    }
    h1,
    h2,
    h3,
    h4,
    h5 {
      margin-bottom: 1em !important;
    }
    h1,
    h2,
    h3,
    h4,
    h5 {
      font-family: ${theme.font.secondary};
      text-transform: none;
      font-weight: ${theme.weights.normal};
    }
    > ul {
      margin-bottom: rem;
      > li {
        line-height: 2em;
      }
    }
    p {
      line-height: 1.7;
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }
`;

export const FooterPayment = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 2rem;
  background: ${theme.footer.logoWrapper.background};
  > div {
    width: 100%;
    max-width: 600px;
  }
`;

const Footer = () => {
  const {
    footer1,
    footer2,
    footer3,
    footer4,
    footer5,
    footerLogo,
    footerPaymentImage,
  } = useContext(SiteContentContext);
  return (
    <>
      <FooterWrapper>
        <div className="newsletter-box">
          <NewsletterBox />
        </div>
        <div className="footer-content">
          <MaxWidth>
            <FooterContentWrapper>
              <div className="wide">
                <div className="image">
                  <Image aspect="8:1" src={footerLogo} />
                </div>
                {footer1}
              </div>
              <div>{footer2}</div>
              <div>{footer3}</div>
              <div>{footer4}</div>
              <div>{footer5}</div>
            </FooterContentWrapper>
          </MaxWidth>
        </div>
      </FooterWrapper>
      {footerLogo && (
        <FooterPayment>
          <div>
            <Image aspect="7:1" src={footerPaymentImage} />
          </div>
        </FooterPayment>
      )}
    </>
  );
};

export default Footer;
