import React, { useContext } from 'react';
import { convertPropsToObject } from '../ContentRender';
import { RowHeader } from './RowHeader';
import { ProductRowComponentWrapper } from './ProductRowComponent';
import AddwishRow from '../../ui/AddwishRow/AddwishRow';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { useAddwish } from '@jetshop/flight-addwish';
import MaxWidth from '../../Layout/MaxWidth';

const AddwishComponent = ({ data, index }) => {
  const props = convertPropsToObject(data);
  const id = props.id.value;
  const slider = props.slider.value === 'yes' ? true : false;

  //ADDWISH
  const { selectedChannel } = useContext(ChannelContext);
  const { addWishResponseBoxItems } = useAddwish({
    boxes: {
      [id]: {
        url: selectedChannel.url,
      },
    },
    pageLoaded: true,
  });

  return (
    <MaxWidth data-index={index}>
      <ProductRowComponentWrapper>
        <RowHeader props={props} />
        <div className="product-row-outer-wrapper">
          <AddwishRow
            data={addWishResponseBoxItems[id]}
            id={id}
            slider={slider}
          />
        </div>
      </ProductRowComponentWrapper>
    </MaxWidth>
  );
};

export default AddwishComponent;
