import React from 'react';
import { styled } from 'linaria/react';
import { Price as UIPrice } from '@jetshop/ui/Price';
import { theme } from '../Theming/Theming';
import { getFontSize } from '../Theming/helpers';

const PriceWrapper = styled('div')`
  font-family: ${theme.price.fontFamily};
  font-weight: ${theme.price.fontWeight};
  ${UIPrice.Normal} {
    color: ${theme.price.normal.color};
  }
  ${UIPrice.New} {
    color: ${theme.price.new.color};
  }
  ${UIPrice.Old} {
    color: ${theme.price.old.color};
  }
`;

const GridPriceWrapper = styled(PriceWrapper)`
  ${UIPrice.Wrapper} {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
  }
  ${UIPrice.Normal} {
    ${getFontSize(theme.price.normal.fontSize)};
  }
  ${UIPrice.New} {
    margin: 0 0.8rem 0 0;
    ${getFontSize(theme.price.new.fontSize)};
  }
  ${UIPrice.Old} {
    margin-left: 2px;
    ${getFontSize(theme.price.old.fontSize)};
    font-weight: ${theme.price.old.fontWeight};
  }
`;

export const Price = ({ className, ...props }) => {
  return (
    <PriceWrapper className={className}>
      <UIPrice {...props} />
    </PriceWrapper>
  );
};

export const GridPrice = ({ className, ...props }) => {
  return (
    <GridPriceWrapper className={className}>
      <UIPrice {...props} />
    </GridPriceWrapper>
  );
};
