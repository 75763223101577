import React, { useContext } from 'react';
import { Query } from 'react-apollo';
import homeCategoriesQuery from './HomeCategoriesQuery.gql';
import SiteContentContext from '../../../SiteContent/SiteContentContext';

//ARRAY MENUCATEGORIES WITH NO SUBMENU (ID)
export const cleanCategories = (categories, blogs = []) => {
  const items = blogs?.map((item) => item?.category?.value?.id);
  const noSubMenu = items ? [...items] : [];
  const removeCat = [];

  let list = [];

  categories.forEach((category) => {
    let item = { ...category };
    if (noSubMenu.includes(category.id)) {
      item.hasSubcategories = false;
      item.subcategories = [];
    }
    if (!removeCat.includes(category.id)) list.push(item);
  });
  return list;
};

const CategoryProvider = ({ children }) => {
  const { blogs } = useContext(SiteContentContext);
  return (
    <Query variables={{ levels: 1 }} query={homeCategoriesQuery}>
      {(result) => {
        const { data } = result;
        const categories = (data && data.categories) || [];
        const categoryTreeRoots = cleanCategories(categories, blogs);
        return children({ categoryTreeRoots, categories });
      }}
    </Query>
  );
};

export default CategoryProvider;
