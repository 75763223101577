export function getId(product) {
  const data =
    product &&
    product.customFields &&
    product.customFields.filter((item) => item.key === 'family-name')[0];

  return data && data.stringValue;
}
export function getField(product, field) {
  const data =
    product &&
    product.customFields &&
    product.customFields.filter((item) => item.key === field)[0];

  return data && data.stringValue;
}
export function getColor(product) {
  return product?.customFields
    ?.filter((item) => item.key === 'color')[0]
    ?.stringValue.toLowerCase();
}
export function filterList(products, term) {
  let list = products.filter((product) => {
    if (getId(product) === term) return product;
    else return null;
  });
  return list.sort((a, b) => (a.articleNumber > b.articleNumber ? 1 : -1));
}

export function filterListOnField(products, mainProduct, field) {
  let list = products.filter((product) => {
    if (getField(product, field) === getField(mainProduct, field))
      return product;
    else return null;
  });
  return list.sort((a, b) => (a.articleNumber > b.articleNumber ? 1 : -1));
}

export function filterOutMainProduct(products, mainProduct) {
  return products.filter((product) => {
    return product.id !== mainProduct.id;
  });
}
