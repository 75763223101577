import React from 'react';
import { convertPropsToObject } from '../ContentRender';

const Space = ({ data, index }) => {
  const props = convertPropsToObject(data);
  const height = props.height?.value;
  return (
    <div
      data-index={index}
      className="space-element"
      style={{ height: height }}
    />
  );
};

export default Space;
