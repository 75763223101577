import React, { useContext, useEffect, useState } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../Theming/Theming';
import SiteContentContext from '../../../SiteContent/SiteContentContext';
import { scrollBar } from '../../../ui/General';
import parse from 'html-react-parser';

const TopBarUspWrapper = styled('div')`
  &.top-bar-usp-list {
    > ul {
      > li {
        display: flex;
        align-items: center;
        font-size: ${theme.topBar.usp.fontSize};
        font-weight: ${theme.topBar.usp.fontWeight} !important;
        ${theme.only.xs} {
          font-size: 0.85rem;
        }
        svg {
          margin-right: 5px;
        }
      }
    }
  }
  &.rolling {
    > ul {
      > li {
        height: 100%;
        opacity: 0;
        position: absolute;
        justify-content: center;
        width: 100%;
        transition: opacity 1.5s;
      }
    }
    &.index-1 > ul > li:nth-of-type(1),
    &.index-2 > ul > li:nth-of-type(2),
    &.index-3 > ul > li:nth-of-type(3) {
      opacity: 1;
    }
  }
  &.scroll {
    > ul {
      ${scrollBar}
      justify-content: unset;
      ::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
      > li {
        white-space: nowrap;
        &:first-child {
          padding-left: 1rem;
          margin-left: auto;
        }
        &:last-child {
          margin-right: auto;
        }
      }
    }
  }
`;

const TopBarUsp = ({ data, aboveLg }) => {
  const { topBarUsp, topBarUspRolling = false } =
    useContext(SiteContentContext);
  const [index, setIndex] = useState(1);
  const outputData = data ? data : topBarUsp;
  const rolling = topBarUspRolling === 'yes' && !aboveLg;

  useEffect(() => {
    if (rolling) {
      const html = parse(
        topBarUsp?.props?.content ? topBarUsp.props.content : ''
      );
      const listItems = html.props?.children?.filter(
        (item) => item.type === 'li'
      ).length;

      let interval = null;
      interval = setInterval(() => {
        const nextIndex = index === listItems ? 1 : index + 1;
        setIndex(nextIndex);
      }, 4000);
      return () => clearInterval(interval);
    }
  }, [index, rolling, topBarUsp]);

  if (outputData)
    return (
      <TopBarUspWrapper
        className={`top-bar-usp-list ${
          rolling ? 'rolling' : 'scroll'
        } index-${index}`}
      >
        {outputData}
      </TopBarUspWrapper>
    );
  return null;
};

export default TopBarUsp;
