import React from 'react';
import { Route } from 'react-router-dom';
import LoadingPage from '../LoadingPage';
import loadable from '@loadable/component';

//BLOG IMPORTS
const LoadableBlogPage = loadable(() => import('./BlogPage'), {
  fallback: <LoadingPage />,
});

const LoadableBlogArticle = loadable(() => import('./BlogArticle'), {
  fallback: <LoadingPage />,
});

const BlogRoute = ({ blog }) => {
  const menu = blog.menu.value === 'yes';
  const path = blog?.category?.value?.primaryRoute?.path;
  const id = blog?.category?.value?.id;
  const styleLook = blog?.styleLook?.value === 'yes';
  const blogPagePath = menu ? [`${path}/:cat`, path] : [path];
  const blogArticlePath = menu ? `${path}/:cat/:item` : `${path}/:item`;
  const configObject = { id, menu, styleLook, blogPath: path };
  return (
    <>
      <Route exact path={blogArticlePath}>
        <LoadableBlogArticle configObject={configObject} />
      </Route>
      <Route exact path={blogPagePath}>
        <LoadableBlogPage configObject={configObject} />
      </Route>
    </>
  );
};

export default BlogRoute;
