import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../Theming/Theming';
import CloseButton from '../../../ui/CloseButton';
import MobileCategories from './MobileCategories';
import UIDrawer from '@jetshop/ui/Modal/Drawer/Drawer';
import DrawerHeader from '../../../ui/DrawerHeader';
import t from '@jetshop/intl';
import { ChannelSelectorDropdown } from '../ChannelSelector/ChannelSelector';
import SiteContentContext from '../../../SiteContent/SiteContentContext';
import { LoginWidgetMobile } from '../Util/LoginWidgetMobile';

const StyledCloseButton = styled(CloseButton)`
  padding: 10px;
  margin: -10px;
  svg {
    color: ${theme.colors.white};
  }
`;

const Scroll = styled('div')`
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  flex: 1 1 auto;
  padding-bottom: 300px;
`;

const MobileNavWrapper = styled('div')`
  height: 100%;
`;

const StyledDrawerHeader = styled(DrawerHeader)`
  background: ${theme.colors.primary};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${theme.mobileHeader.nav.padding};
  margin: 0 0;
  border: none;
`;

const LogoWrapper = styled('div')`
  padding: 10px 0;
  h3 {
    color: ${theme.colors.white};
  }
`;

const NavLists = styled('div')`
  padding: 0 ${theme.mobileHeader.nav.padding};
  > * {
    margin-top: 3rem;
  }
  ul {
    li {
      display: block;
      line-height: 42px;
      text-decoration: none;
      border-bottom: 1px solid ${theme.colors.border};
    }
  }
  .round-icon {
    position: relative;
    display: flex;
  }
`;

const UserControl = styled('div')`
  padding: 0 ${theme.mobileHeader.nav.padding};
  margin-top: 3rem;
  > * {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    .icon-wrapper {
      position: relative;
      margin-right: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      background: ${theme.colors.lightgrey};
      font-size: 1.1rem;
      .number-badge {
        right: -7px;
        top: -2px;
      }
    }
  }
  .number-badge {
    width: 18px;
    height: 18px;
    font-size: 0.9rem;
  }
`;

const ChannelSelectorWrapper = styled('div')`
  padding: 0 ${theme.mobileHeader.nav.padding};
  margin-top: 2rem;
  .channel-selector-button {
    height: 36px;
  }
  .channel-selector-dropdown {
    width: calc(100% + 2rem);
  }
`;

const MobileNav = ({
  categories,
  categoryTreeRoots,
  hideTarget,
  isOpen,
  right,
  ...rest
}) => {
  const { mobilePageList } = useContext(SiteContentContext);
  return (
    <UIDrawer isOpen={isOpen} right={right} size={360}>
      <MobileNavWrapper>
        <StyledDrawerHeader>
          <LogoWrapper>
            <h3>{t('Menu')}</h3>
          </LogoWrapper>
          <StyledCloseButton onClick={hideTarget} />
        </StyledDrawerHeader>
        <Scroll>
          <MobileCategories
            hideTarget={hideTarget}
            categories={categoryTreeRoots}
            categoryTreeRoots={categoryTreeRoots}
            isOpen={isOpen}
          />
          <NavLists onClick={hideTarget}>{mobilePageList}</NavLists>
          <UserControl onClick={hideTarget}>
            <LoginWidgetMobile className="round-icon" />
          </UserControl>
          <ChannelSelectorWrapper>
            <ChannelSelectorDropdown />
          </ChannelSelectorWrapper>
        </Scroll>
      </MobileNavWrapper>
    </UIDrawer>
  );
};
export default MobileNav;
