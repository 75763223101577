import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { VariantHeading } from '../VariantSelector/VariantButtons';
import ProductLink from '@jetshop/ui/ProductLink';
import { theme } from '../../Theming/Theming';
import SiteContentContext from '../../SiteContent/SiteContentContext';
import { getColor } from './helpers';
import { useState } from 'react';
import { Color } from './Color';
import PlusIcon from '../../ui/icons/PlusIcon';

const ButtonWrapper = styled('div')`
  > ul {
    display: flex;
    margin: 0 -5px;
    flex-wrap: wrap;
    > li {
      text-align: center;
      width: auto;
      margin: 0 5px 5px;
      border: 1px solid white;
      overflow: hidden;
      border-radius: 50%;
      &.selected{
        border-color:${theme.colors.font};
      }
      > * {
        display: block;
      }
      .color {
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        position: relative;
        border: 4px solid white;
        overflow: hidden;
        &.no-color-code {
          &:after {
            content: '';
            transform: rotate(45deg);
            position: absolute;
            height: 2px;
            top: calc(50% - 1px);
            width: 100%;
            background: #999;
            left: 0;
          }
        }
      }
    }
  }
  &:not(.grid){
    > ul {
      > li {
        position: relative;
      }
    }
  }
  &.grid {
    overflow: hidden;
    position: relative;
    &:after {
      position: absolute;
      right: 0;
      width: 50px;
      top: 0;
      content: '';
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 80%,
        rgba(255, 255, 255, 1) 100%
      );
      height: 100%;
    }
    > ul {
      flex-wrap: nowrap;
      margin: 0 -2px;
      > li {
        margin: 2px;
        min-width: 12px;
        border: 1px solid ${theme.colors.borderLight};
        border-radius: 50%;
        padding: 1px;
        &.plus{
          border-color: white;
        }
        &.selected {
          border-color: ${theme.colors.borderInput};
        }
        &.hovered {
          border-color: ${theme.colors.font};
        }
        .color {
          overflow: hidden;
          width: 15px;
          height: 15px;
          border-width: 1px;
          &.no-color-code {
            &:after {
              height: 1px;
              top: 3.6px;
            }
        }
      }
    }
  }
`;

export const SiblingButtons = ({
  productList,
  mainProduct,
  grid = false,
  setImage = false,
  setProduct = false,
}) => {
  const { colorTitle, colorList } = useContext(SiteContentContext);
  const [active, setActive] = useState(false);

  const showMore = productList.length > 8 && grid;

  const list = showMore
    ? productList.filter((item, index) => index < 6)
    : productList;

  return (
    <ButtonWrapper className={`sibling-button-wrapper ${grid ? 'grid' : ''}`}>
      {!grid && <VariantHeading>{colorTitle}</VariantHeading>}
      <ul>
        {list.map((product, index) => {
          const currentProduct = product.id === mainProduct.id;
          const Tag = currentProduct ? 'div' : ProductLink;
          const color = getColor(product);
          const colorCode = color && colorList[color];
          return (
            <li
              key={product.id}
              className={`hex-color ${
                product.stockStatus?.buyable ? 'buyable' : 'not-buyable'
              } ${currentProduct ? 'selected' : ''} ${
                active === index ? 'hovered' : ''
              }`}
              onMouseEnter={() => {
                if (setImage && product?.images.length > 0) {
                  setImage(product.images[0]);
                  setActive(index);
                }
              }}
              onClick={() => {}}
            >
              <Tag
                product={product}
                onClick={(e) => {
                  if (setProduct) {
                    setProduct(product);
                    e.preventDefault();
                  }
                }}
              >
                <Color
                  color={colorCode}
                  className={`color ${colorCode ? '' : 'no-color-code'}`}
                />
              </Tag>
            </li>
          );
        })}
        {showMore && (
          <li className="plus">
            <ProductLink product={mainProduct}>
              <PlusIcon />
            </ProductLink>
          </li>
        )}
      </ul>
    </ButtonWrapper>
  );
};
