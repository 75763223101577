import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theming';
import { convertPropsToObject } from '../ContentRender';
import { RowHeader } from './RowHeader';
import ProductRow from '../../ui/ProductRow/ProductRow';
import MaxWidth from '../../Layout/MaxWidth';

const StyledMaxWidth = styled(MaxWidth)`
  padding-right: 0;
  padding-left: 0;
`;

export const ProductRowComponentWrapper = styled('div')`
  margin: 6% 0;
  ${theme.above.lg} {
    margin: 4% 0 3%;
  }
  .row-header {
    margin: 0 0 2rem 0;
    ${theme.below.md} {
      margin: 0 0 1rem 0;
    }
  }
`;

const ProductRowComponent = ({ data, index }) => {
  const props = convertPropsToObject(data);
  const rowProps = {
    id: props.category.value && props.category.value.id,
    limit: props.limit.value,
    orderBy: props.orderBy.value,
    orderByDirection: props.orderByDirection.value,
    slider: props.slider.value === 'yes' ? true : false,
  };

  if (!rowProps.id) return null;
  return (
    <StyledMaxWidth data-index={index}>
      <ProductRowComponentWrapper>
        <RowHeader props={props} />
        <ProductRow {...rowProps} />
      </ProductRowComponentWrapper>
    </StyledMaxWidth>
  );
};

export default ProductRowComponent;
