export const config = {
  routes: {
    favourites: {
      path: process.env.REACT_APP_FAV_PATH || '/favoriter',
    },
    brand: {
      id: 0,
    },
    category: {
      hide: false,
    },
    product: {
      hide: false,
    },
  },
  settings: {
    storeName: 'Siden Selma',
    siteContentId: 104,
  },
};
