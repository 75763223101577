import { useContext } from 'react';
import SiteContentContext from '../../SiteContent/SiteContentContext';

export const usePrimaryBlogPath = () => {
  const { blogs } = useContext(SiteContentContext);

  //PATH TO PRIMARY BLOG
  const blogPath =
    blogs && blogs.length > 0 && blogs[0].category?.value?.primaryRoute?.path;

  return blogPath;
};
