import React, { memo, useState } from 'react';
import { styled } from 'linaria/react';
import CategoryLink from '@jetshop/ui/CategoryLink';
import { StyledCarrot } from '@jetshop/ui/Select/Components';
import t from '@jetshop/intl';
import { theme } from '../../../Theming/Theming';
import { useLocation } from 'react-router';
import deepCategoriesQuery from '../Categories/DeepCategoriesQuery.gql';
import { Query } from 'react-apollo';
import { cleanCategories } from '../Categories/CategoryProvider';

const CategoryWrapper = styled('div')`
  position: relative;
  overflow: hidden;
`;

const CategoryButton = styled('button')`
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: inherit;
  text-decoration: none;
  padding: 0 0 0 ${theme.mobileHeader.nav.padding};
  background: ${theme.colors.white};
  ${StyledCarrot} {
    margin-right: 0px;
  }
  > a {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    align-items: center;
    .image-holder {
      margin-right: 10px;
      display: block;
      width: 20px;
    }
  }
  .icon-holder {
    padding: 0 2rem;
    &.open,
    &:hover {
      background: ${theme.colors.primary};
      svg {
        color: ${theme.colors.white};
      }
    }
  }
  border-bottom: 1px solid ${theme.colors.borderLight};
  .icon-holder {
    border-left: 1px solid ${theme.colors.borderLight};
  }
  &[data-level='1'] {
    font-family: ${theme.mobileHeader.nav.lv1.fontFamily};
    font-weight: ${theme.mobileHeader.nav.lv1.fontWeight};
    font-size: ${theme.mobileHeader.nav.lv1.fontSize};
    line-height: ${theme.mobileHeader.nav.lv1.lineHeight};
    text-transform: ${theme.mobileHeader.nav.lv1.textTransform};
  }
  &[data-level='2'] {
    font-size: ${theme.mobileHeader.nav.lv2.fontSize};
    line-height: ${theme.mobileHeader.nav.lv2.lineHeight};
    &,
    .icon-holder {
      border-color: white;
    }
    background: ${theme.colors.lightgrey};
    padding-left: 3rem;
  }
  &[data-level='3'] {
    font-size: ${theme.mobileHeader.nav.lv3.fontSize};
    line-height: ${theme.mobileHeader.nav.lv3.lineHeight};
    padding-left: 4rem;
  }
  &[data-level='4'] {
    font-size: ${theme.mobileHeader.nav.lv4.fontSize};
    line-height: ${theme.mobileHeader.nav.lv4.lineHeight};
    padding-left: 5rem;
  }
  &.active,
  &.active > a {
    //color: ${theme.mobileHeader.nav.activeColor};
  }
  &.selected,
  &.selected > a {
    color: ${theme.mobileHeader.nav.selectedColor};
  }
  &.show-all {
    text-decoration: underline;
    span {
      text-transform: lowercase;
    }
  }
`;

function inPath(category, pathname) {
  let currentPath = pathname;
  let returnObject = {
    inPath: false,
    exactPath: false,
  };

  if (
    category.primaryRoute &&
    currentPath.search(category.primaryRoute.path) !== -1
  ) {
    returnObject.inPath = true;
    returnObject.exactPath = currentPath === category.primaryRoute.path;
  }
  return returnObject;
}

const Category = memo(
  ({
    children,
    category,
    defaultOpen = false,
    level,
    hideTarget,
    activeCategory,
    setActiveCategory,
  }) => {
    const hasSubs = category.subcategories && category.subcategories.length > 0;
    const location = useLocation();
    let classNames = [];
    const po = inPath(category, location.pathname);

    if (po.inPath) {
      defaultOpen = true;
      classNames.push('active');
    }

    if (po.exactPath || activeCategory === category.id)
      classNames.push('selected');

    const [isOpen, setOpen] = useState(defaultOpen);
    return (
      <CategoryWrapper>
        <CategoryButton data-level={level} className={classNames.join(' ')}>
          <CategoryLink
            onClick={(e) => {
              hideTarget();
              setActiveCategory(category.id);
              setOpen(!isOpen);
            }}
            category={category}
          >
            {category.name}
          </CategoryLink>
          {hasSubs && (
            <div
              onClick={() => {
                setOpen(!isOpen);
              }}
              className={`${isOpen ? 'open' : ''} icon-holder`}
            >
              <StyledCarrot className={isOpen ? 'open' : ''} />
            </div>
          )}
        </CategoryButton>
        {isOpen && hasSubs && (
          <div className="subcategories">
            <CategoryButton data-level={level + 1} className="show-all">
              <CategoryLink
                category={category}
                onClick={() => {
                  hideTarget();
                  setActiveCategory(category.id);
                  setOpen(!isOpen);
                }}
              >
                {t('Show all in ')} {category.name}
              </CategoryLink>
            </CategoryButton>
            {children}
          </div>
        )}
      </CategoryWrapper>
    );
  }
);

const CategoryTree = ({
  parent = null,
  categories,
  level = 1,
  hideTarget,
  activeCategory,
  setActiveCategory,
}) => {
  if (categories && categories.length > 0) {
    return categories.map((category) => (
      <Category
        category={category}
        key={category.id}
        level={level}
        hideTarget={hideTarget}
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
      >
        <CategoryTree
          parent={category}
          categories={category.subcategories}
          level={level + 1}
          hideTarget={hideTarget}
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
        />
      </Category>
    ));
  } else return null;
};

const MobileCategories = ({ categories, hideTarget, isOpen }) => {
  const [activeCategory, setActiveCategory] = useState('trail');
  const [open, setOpen] = useState(false);
  if (isOpen && !open) setOpen(true);
  if (open)
    return (
      <Query variables={{ levels: 1 }} query={deepCategoriesQuery}>
        {(result) => {
          const { data } = result;
          const fullCategories =
            (data && cleanCategories(data.categories)) || categories;
          if (categories) {
            return (
              <CategoryTree
                categories={fullCategories}
                hideTarget={hideTarget}
                activeCategory={activeCategory}
                setActiveCategory={setActiveCategory}
              />
            );
          } else return null;
        }}
      </Query>
    );
  if (categories && categories.length > 0) {
    return (
      <CategoryTree
        categories={categories}
        hideTarget={hideTarget}
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
      />
    );
  } else return null;
};

export default MobileCategories;
