import React from 'react';
import { styled } from 'linaria/react';
import Spinner from './Spinner';
import { Link } from 'react-router-dom';
import t from '@jetshop/intl';
import { theme } from '../Theming/Theming';
import { getFontSize } from '../Theming/helpers';

const buttonStyles = `
  height: 54px;
  font-family: ${theme.button.fontFamily};
  font-weight: ${theme.button.fontWeight};
  text-transform: ${theme.button.textTransform};
  ${getFontSize(theme.button.fontSize)};
  background-color: ${theme.button.background};
  color: ${theme.button.color};
  padding: 0 0.5rem;
  letter-spacing: ${theme.button.letterSpacing};
  ${theme.above.md} {
    max-width: 100%;
  }
  >a{
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  > a,
  > a:focus {
    color: ${theme.button.color};
  }
  svg {
    fill: white;
    height: 1em;
    width: 1em;
    margin-right: 1em;
  }
  :active {
    color: ${theme.button.color};
  }
  :disabled:not(.same) {
    background: #f7f7f7;
    border: 1px solid #dedede;
    color: #808080;
    opacity: 0.7;
  }

  &.secondary {
    background-color: ${theme.button.backgroundSecondary};
    color: ${theme.button.colorSecondary};
    :active,
    > a,
    > a:focus {
      color: ${theme.button.colorSecondary};
    }
  }
  &.buy,
  &.cta {
    background-color: ${theme.colors.buy};
    &:hover {
      background-color: ${theme.colors.buy};
    }
  }
  &.hollow {
    background: none;
    transition: all 0.2s;
    border: 1px solid ${theme.colors.border};
    color: ${theme.colors.font};
    font-weight: ${theme.weights.medium};
    :active,
    > a,
    > a:focus {
      color: ${theme.colors.font};
    }
    &:hover {
      border-color: ${theme.colors.font};
    }
  }
  &.hollow.list {
    &:hover {
      background-color: ${theme.button.background};
      color: ${theme.button.color};
      border-color: ${theme.button.background};
      > a,
      > a:focus {
        color: ${theme.button.color};
      }
    }
  }
  &.list,
  &.low {
    height: 40px;
    ${getFontSize(theme.button.ListFontSize)};
  }
  &.message{
    opacity: 0.8;
  }
`;

const StyledButton = styled('button')`
  ${buttonStyles}
`;

const StyledLink = styled(Link)`
  ${buttonStyles}
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledAtag = styled('a')`
  ${buttonStyles}
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledSpinner = styled(Spinner)`
  height: 25px;
  circle.path {
    stroke: white;
  }
`;

export const ButtonUILink = ({
  children,
  loading,
  loadingText = t('Hold on a moment...'),
  ...props
}) => <StyledLink {...props}>{children}</StyledLink>;

export const ButtonUILinkExt = ({
  children,
  loading,
  loadingText = t('Hold on a moment...'),
  ...props
}) => <StyledAtag {...props}>{children}</StyledAtag>;

export const ButtonUI = ({
  children,
  loading,
  loadingText = t('Hold on a moment...'),
  ...props
}) => (
  <StyledButton {...props}>
    {loading ? (
      <>
        {loadingText}
        <StyledSpinner />
      </>
    ) : (
      <>{children}</>
    )}
  </StyledButton>
);
