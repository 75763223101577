import React from 'react';
import BlogItemList from './BlogItemList';
import BlogItem from './BlogItem';
import { styled } from 'linaria/react';
import MaxWidth from '../Layout/MaxWidth';
import { theme } from '../Theming/Theming';
import { useBlog } from './hook/useBlog';
import { config } from '../../shop.config.custom';
import { ScrollBox } from '../ui/ScrollBox';

const BlogOnPageWrapper = styled('div')`
  ${theme.above.lg} {
    .slick-prev {
      //left: -20px;
    }
    .slick-next {
      //right: -20px;
    }
  }
`;

const BlogOnPage = ({ limit = 20, slider }) => {
  const { articles } = useBlog({ ...config.routes.blog, limit });
  return (
    <BlogOnPageWrapper>
      {slider ? (
        <MaxWidth>
          <ScrollBox>
            {articles.map((category, index) => (
              <BlogItem
                category={category}
                key={index}
                index={index}
                className="item-card"
              />
            ))}
          </ScrollBox>
        </MaxWidth>
      ) : (
        <BlogItemList categories={articles} blogPage={false} />
      )}
    </BlogOnPageWrapper>
  );
};

export default BlogOnPage;
