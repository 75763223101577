import Image from '@jetshop/ui/Image';
import { transparentDataImg } from '@jetshop/ui/Image/Image';
import ProductLink from '@jetshop/ui/ProductLink';
import React from 'react';
import { styled } from 'linaria/react';
import { GridPrice } from '../Price';
import t from '@jetshop/intl';
import { Favourite } from '../ProductList/Favourite';
import { theme } from '../Theming/Theming';
import { getFontSize } from '../Theming/helpers';
import { Price as UIPrice } from '@jetshop/ui/Price';
import SiblingProducts from '../ProductPage/SiblingProducts/SiblingProducts';
import { imageOverlay } from '../ui/General';
import ProductBadges from './ProductBadges';

export const Details = styled('div')`
  margin: 1rem 0 0.5rem;
  position: relative;
`;

export const DetailsInner = styled('div')`
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-end;
  > * {
    flex-grow: 0;
    width: 100%;
  }
  .sibling-products-wrapper {
    margin-top: 7px;
    height: 30px;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  ${imageOverlay}
`;

export const Name = styled('h4')`
  margin: 0 0 0.3rem 0 !important;
  padding: 0;
  padding-right: 1.8rem;
  align-self: stretch;
  flex-grow: 1;
  line-height: 1.3;
  font-family: ${theme.productGrid.product.name.fontFamily} !important;
  font-weight: ${theme.productGrid.product.name.fontWeight} !important;
  text-transform: none;

  overflow: hidden;
  letter-spacing: ${theme.typography.letterSpacing};
  ${theme.below.md} {
    height: 40px;
  }
`;
export const SubName = styled('p')`
  margin: 0 0 0.7rem 0;
  align-self: stretch;
  flex-grow: 1;
  line-height: 1.2;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-transform: uppercase;
  font-size: 0.8rem;
  ${theme.below.md} {
    font-size: 0.8rem;
  }
`;
export const ListPrice = styled(GridPrice)``;

export const ProductCardInner = styled('div')`
  padding: ${theme.productGrid.product.padding.sm};
  background-color: ${theme.productGrid.product.background};
  border: ${theme.productGrid.product.border};
  position: relative;
  line-height: 1;
  min-width: 0;
  ${theme.above.md} {
    padding: ${theme.productGrid.product.padding.md};
  }
  ${theme.above.xl} {
    padding: ${theme.productGrid.product.padding.lg};
  }
  > a {
    color: inherit;
    display: block;
  }
  > button {
    width: 100%;
  }
  &[data-category='true'] {
    ${SubName} {
      ${getFontSize(theme.typography.header.fontSize[5])}
    }
    ${UIPrice.Normal} {
      ${getFontSize(theme.typography.header.fontSize[3])};
    }
    ${UIPrice.New} {
      ${getFontSize(theme.typography.header.fontSize[3])};
    }
    ${UIPrice.Old} {
      ${getFontSize(theme.typography.header.fontSize[4])};
    }
  }
`;

const ProductCardComponent = ({
  product,
  imageAspect,
  imageSizes,
  children,
  showFavorite,
  loadImageEagerly,
  categoryPath,
  categoryPage,
  close = () => {},
  ...rest
}) => {
  //HAS IMAGES
  const hasImages = product.images && product.images.length > 0;

  //STANDARD PRODUCT IMAGE
  const images = product?.images;

  let badges = [...product.badges];

  product.isPackage &&
    badges.push({
      location: 'TOP_RIGHT',
      name: 'Package',
      text: t('Package'),
      style: 'package-badge',
    });

  return (
    <ProductCardInner
      className="product-card-inner"
      data-category={categoryPage}
      onClick={close}
    >
      <ProductLink product={product} categoryPath={categoryPath} {...rest}>
        {hasImages ? (
          <ImageWrapper>
            <Image
              sizes={imageSizes}
              aspect={imageAspect}
              alt={images[0].alt}
              src={images[0].url}
              modifiedDate={images[0].modifiedDate}
              critical={loadImageEagerly}
            />
            <ProductBadges badges={product.badges} />
          </ImageWrapper>
        ) : (
          <Image src={transparentDataImg} aspect={imageAspect} />
        )}
      </ProductLink>
      <Details className="product-card-detail">
        {showFavorite && (
          <Favourite
            product={product}
            style={{
              position: 'absolute',
              top: '1px',
            }}
          />
        )}
        <DetailsInner>
          <header>
            <ProductLink product={product} categoryPath={categoryPath}>
              <Name>{product.name}</Name>
            </ProductLink>
            <SubName className="sub-name">{product.subName}</SubName>
          </header>
          <ListPrice
            hidePrice={product.hidePrice}
            price={product.price}
            previousPrice={product.previousPrice}
          />
          {showFavorite && (
            <div className="sibling-products-wrapper">
              <SiblingProducts product={product} grid={true} />
            </div>
          )}
        </DetailsInner>
      </Details>
      {children}
    </ProductCardInner>
  );
};

export function ProductCard({
  product,
  className,
  imageAspect = theme.productGrid.product.imageAspect,
  imageSizes = [1 / 2, 1 / 2, 1 / 3, 1 / 4],
  forwardRef,
  as = 'div',
  children,
  showFavorite = true,
  showVariants = true,
  loadImageEagerly = false,
  categoryPage = false,
  ...linkProps
}) {
  const Tag = as;

  //PROPS TO PASS TO PRODUCTCARD COMP
  const props = {
    imageAspect,
    imageSizes,
    children,
    showFavorite,
    loadImageEagerly,
    categoryPage,
    ...linkProps,
  };
  return (
    <Tag
      className={`product-card ${className}`}
      data-testid="product"
      data-product-card
      ref={forwardRef}
    >
      <ProductCardComponent product={product} {...props} />
    </Tag>
  );
}
